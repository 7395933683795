.loginButton {
  height: 40px;
  text-align: center !important;
  margin-bottom: 15px !important;
  color: #fff !important;
  background-color: #5bc0de !important;
  border-color: #46b8da !important;
  width: 300px;
}

.loginButtonLabel {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
}